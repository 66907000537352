@import url(./assets/fonts/Aeroport/stylesheet.css);
@import url(./assets/fonts/Alter/stylesheet.css);
@import url(./assets/fonts/Igra/stylesheet.css);
@import url(./assets/fonts/AgFatum/stylesheet.css);
:root {
  --white: #fff;
  --black: #000;
  --blue: #053867;
  --bg: #1d0634;
}
* {
  box-sizing: border-box;
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Aeroport', sans-serif;
  background: var(--bg);
  color: var(--white);
  font-size: 16px;
  font-variant-ligatures: none;
}
body.open {
  overflow: hidden;
}
img {
  border: 0;
}
h1,
.h1 {
  font-size: 40px;
  font-weight: normal;
  margin: 0 0 15px;
  padding: 0;
}
h2,
.h2 {
  font-size: 30px;
  font-weight: normal;
  margin: 0 0 15px;
  padding: 0;
}
h3,
.h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 15px;
  padding: 0;
}
input,
textarea,
a,
button {
  outline: none;
}
a {
  color: var(--blue);
  transition: all 0.3s ease;
}
a:hover {
  color: var(--blue);
  text-decoration: none;
}
.dn {
  display: none;
}
.clr {
  clear: both;
}
.col {
  vertical-align: top;
  display: inline-block;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--black);
  -webkit-box-shadow: 0 0 0 1000px var(--white) inset;
}
.wrapper {
  position: relative;
  overflow: hidden;
}
.page {
  position: relative;
  z-index: 2;
}
.page--article {
  min-height: 100vh;
}
.bg {
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 1;
  line-height: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0, 0, 0, 1) 0s;
}
.bg div {
  width: 100%;
  height: 100%;
  transition: background 1s ease;
}
.tl {
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 99;
  transition: all 0.3s ease;
}
.tl.hidden {
  transform: translateY(-100px);
}
.br {
  position: fixed;
  bottom: 30px;
  right: 35px;
  text-transform: uppercase;
  color: var(--white);
  font-size: 14px;
  line-height: 120%;
  z-index: 97;
}
.br:before {
  content: "";
  background: var(--white);
  opacity: 0.3;
  width: 1px;
  height: 45px;
  position: absolute;
  bottom: -30px;
  left: -35px;
  transform: skewX(-20deg);
}
.br a {
  color: var(--white);
}
.br a:hover {
  color: var(--white);
}
.bl {
  position: fixed;
  z-index: 97;
  bottom: 65px;
  left: 25px;
  text-transform: uppercase;
  color: var(--white);
  font-size: 7.5px;
  line-height: 120%;
  opacity: 0.2;
  transform: rotate(-90deg) translateY(100%);
  width: 240px;
  transform-origin: left bottom;
}
.bl:before {
  content: "";
  background: var(--white);
  opacity: 0.9;
  width: 3px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: -45px;
  transform: skewX(-60deg);
}
.tr {
  position: fixed;
  top: 0;
  right: 0;
  padding: 18px 20px 18px 0;
  z-index: 99;
  width: 140px;
  height: 110px;
  background: var(--black);
  display: flex;
  align-items: flex-end;
}
.tr:hover .hamburger:not(.close) span {
  margin-left: 10px;
}
.tr:hover .hamburger:not(.close) span:first-child {
  margin-left: 45px;
}
.tr:hover .hamburger:not(.close) span:last-child {
  margin-left: 40px;
}
.logo {
  color: var(--white);
  display: inline-block;
  font-family: 'AGFatumC', sans-serif;
  text-decoration: none;
  font-size: 30px;
  line-height: 120%;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.logo:hover {
  color: var(--white);
  text-decoration: none;
}
.logo--black {
  color: var(--black);
}
.logo--black:hover {
  color: var(--black);
}
.hamburger {
  display: flex;
  flex-direction: column;
  transition: all 0.6s cubic-bezier(0, 0, 0, 1) 0s;
}
.hamburger span {
  display: inline-block;
  width: 50px;
  height: 2px;
  background: var(--white);
  margin: 3px 0 3px 16px;
  transition: all 0.6s cubic-bezier(0, 0, 0, 1) 0s;
}
.hamburger span:first-child {
  margin-left: 30px;
}
.hamburger span:last-child {
  margin-left: 50px;
}
.hamburger.open span {
  margin-left: 65px;
}
.hamburger.open span:first-child {
  margin-left: 25px;
}
.hamburger.open span:last-child {
  margin-left: 45px;
}
.hamburger.close {
  margin-bottom: 20px;
}
.hamburger.close span:nth-child(2) {
  opacity: 0;
}
.hamburger.close span:first-child {
  transform: rotate(45deg);
  margin: 0 0 -5px 40px;
}
.hamburger.close span:last-child {
  transform: rotate(-45deg);
  margin: -5px 0 0 40px;
}
.pic {
  display: inline-block;
  width: 1180px;
  height: 880px;
  overflow: hidden;
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.pic div {
  width: 100%;
  height: 100%;
  background: no-repeat center / cover;
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 200px;
}
.section > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
}
.section--1 {
  justify-content: flex-end;
  overflow: hidden;
}
.section--1 > div {
  justify-content: flex-end;
}
.section--1 .pic {
  transform: translateY(-30px);
}
.section--1 .pic div {
  transform: translateY(0);
}
.section--1 .title {
  font-size: 111px;
  transform: rotate(-90deg);
}
.section--1 .title-outer {
  left: 530px;
}
.section--1 .title > div:first-child {
  font-size: 80px;
  margin-left: 40px;
}
.section--1 .btn-fly {
  margin: -75px 0 0 -170px;
}
.section--1 .square {
  left: 990px;
  top: 100%;
  margin-top: -190px;
  transform: translateY(0);
}
.section--1 .line {
  display: none;
}
.section--2 .title {
  font-size: 128px;
}
.section--2 .title-outer {
  left: 1480px;
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.section--2 .title > div {
  transform: translateX(-200px);
}
.section--2 .title > div:nth-child(2) {
  font-size: 71px;
  margin-left: 160px;
}
.section--2 .title.up > div {
  transform: translateX(200px);
}
.section--2 .btn-fly {
  margin: 90px 0 0 170px;
}
.section--2 .pic {
  width: 1240px;
}
.section--2 .square {
  left: 890px;
  top: -30px;
}
.section--2 .line {
  left: 190px;
  top: 100%;
  margin-top: -150px;
}
.section--2.active .title-outer {
  transform: translateY(-380px);
}
.section--3 {
  justify-content: flex-end;
}
.section--3 > div {
  justify-content: flex-end;
}
.section--3 .title {
  font-size: 147px;
}
.section--3 .title-outer {
  left: 550px;
}
.section--3 .title > div:nth-child(2) {
  font-size: 78px;
  margin-left: 250px;
  margin-top: -10px;
}
.section--3 .btn-fly {
  margin: 210px 0 0 -710px;
}
.section--3 .square {
  left: 200px;
  top: -50px;
}
.section--3 .line {
  left: 1325px;
  top: 100%;
  margin-top: -240px;
}
.section--4 .title {
  font-size: 125px;
  color: var(--black);
}
.section--4 .title-outer {
  left: 1600px;
  transform: translateX(-100px);
}
.section--4 .title > div:nth-child(1) {
  margin-left: 330px;
}
.section--4 .title > div:nth-child(3) {
  font-size: 71px;
  margin-left: 60px;
}
.section--4 .btn-fly {
  margin: 320px 0 0 80px;
}
.section--4 .square {
  left: 1320px;
  top: -20px;
  z-index: -2;
}
.section--4 .line {
  left: 225px;
  top: 100%;
  margin-top: -220px;
}
.section--5 {
  justify-content: flex-end;
}
.section--5 > div {
  justify-content: flex-end;
}
.section--5 .pic {
  width: 1100px;
  height: 800px;
}
.section--5 .title {
  font-size: 68px;
}
.section--5 .title-outer {
  left: 550px;
  transform: translateX(-50px);
}
.section--5 .title > div:nth-child(1) {
  margin-left: 50px;
}
.section--5 .title > div:nth-child(2) {
  font-size: 172px;
  margin-left: 70px;
  line-height: 100%;
}
.section--5 .btn-fly {
  margin: 380px 0 0 -140px;
}
.section--5 .square {
  left: 150px;
  top: 100%;
  margin-top: -190px;
}
.section--5 .line {
  left: 1190px;
  top: 0;
  margin-top: -80px;
}
.section--6 {
  margin-bottom: 0!important;
}
.section--6 .title {
  font-size: 150px;
}
.section--6 .title-outer {
  left: 1450px;
  transform: translateY(-10px);
}
.section--6 .title > div:nth-child(2) {
  font-size: 85px;
  margin-left: 150px;
  margin-top: -10px;
}
.section--6 .btn-fly {
  margin: 270px 0 0 780px;
}
.section--6 .square {
  left: 1070px;
  top: -20px;
  z-index: 0;
}
.section--6 .line {
  left: 805px;
  top: 100%;
  margin-top: -215px;
}
.section.prev .pic {
  transform: translateY(-250px);
}
.section.prev .square {
  transform: translateY(-500px);
}
.section.prev .line {
  transform: translateY(-500px);
}
.section.next .pic {
  transform: translateY(250px);
}
.section.next .square {
  transform: translateY(500px);
}
.section.next .line {
  transform: translateY(500px);
}
.square {
  width: 190px;
  height: 190px;
  position: absolute;
  z-index: 35;
  line-height: 0;
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.square div {
  width: 100%;
  height: 100%;
}
.line {
  width: 25px;
  height: 240px;
  position: absolute;
  z-index: 36;
  line-height: 0;
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.line div {
  width: 100%;
  height: 100%;
}
.voile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
@keyframes bounceH {
0% {
    transform: translateX(0);
}
50% {
    transform: translateX(20%);
}
100% {
    transform: translateX(0);
}
}
@keyframes bounceV {
0% {
    transform: translateY(0);
}
50% {
    transform: translateY(20%);
}
100% {
    transform: translateY(0);
}
}
.title {
  font-family: 'AGFatumC', sans-serif;
  text-transform: uppercase;
  line-height: 120%;
  letter-spacing: 0.004em;
  transform: rotate(-90deg);
  font-size: 191px;
  transform-origin: center center;
}
.title-outer {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  z-index: 3;
}
.title-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title > div {
  white-space: nowrap;
  transform: translateX(-100px);
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.title > div:nth-child(2) {
  transform: translateX(-150px);
}
.title > div:nth-child(3) {
  transform: translateX(-200px);
}
.title > div:nth-child(4) {
  transform: translateX(-250px);
}
.title > div:nth-child(1) {
  transition-duration: 2s;
}
.title > div:nth-child(2) {
  transition-duration: 3s;
}
.title > div:nth-child(3) {
  transition-duration: 4s;
}
.title > div:nth-child(4) {
  transition-duration: 5s;
}
.title > div:nth-child(5) {
  transition-duration: 6s;
}
.title.up > div {
  transform: translateX(100px);
}
.title.up > div:nth-child(2),
.title.up > div:nth-child(3),
.title.up > div:nth-child(4) {
  transform: translateX(100px);
}
.title.show > div {
  transform: translateX(0) translateY(0);
}
.title.show > div:nth-child(2),
.title.show > div:nth-child(3),
.title.show > div:nth-child(4) {
  transform: translateX(0) translateY(0);
}
.btn {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 19px;
  line-height: 130%;
  z-index: 3;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  padding: 10px;
  letter-spacing: 0.004em;
}
.btn > span {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.btn-fly {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.menu {
  position: fixed;
  z-index: 98;
  background: var(--black);
  width: 100vw;
  height: 100%;
  overflow: auto;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
}
.menu-item {
  font-size: 55px;
  font-family: 'AGFatumC', sans-serif;
  text-transform: uppercase;
  margin-bottom: 55px;
  z-index: 2;
  position: relative;
  transition: all 1s ease;
}
.menu-item:before {
  content: "";
  width: 290px;
  height: 5px;
  top: 50%;
  left: -450px;
  transform: translateY(-50%);
  background: var(--white);
  position: absolute;
  transition: all 1.5s ease;
}
.menu-item:hover {
  padding-left: 240px;
}
.menu-item:hover:before {
  left: -120px;
}
.menu-float {
  position: absolute;
  width: 600px;
  height: 370px;
  line-height: 0;
  z-index: 1;
}
.menu-float-item {
  background: no-repeat center / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.menu-float-item.active {
  opacity: 1;
}
.menu__bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  align-items: flex-end;
  font-size: 26px;
  padding: 40px 80px;
  text-transform: uppercase;
}
.menu__bottom a {
  color: var(--white);
}
.menu__link {
  margin-right: 100px;
  transition: all 0.3s ease;
}
.menu__link.active {
  font-size: 40px;
}
.menu__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 70px 80px 0;
  overflow: hidden;
}
.article__bg {
  height: 100vh;
  width: 100%;
  background: no-repeat center / cover;
  position: relative;
}
.article__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  opacity: 0.4;
  display: none;
}
.article__bg > div {
  width: 100%;
  height: 100%;
  background: no-repeat center / cover;
}
.article__name {
  position: absolute;
  top: 50vh;
  left: 50%;
  width: 90%;
  transform: translate(-50%, calc(-50% - 5vh));
  font-family: 'AGFatumC', sans-serif;
  text-transform: uppercase;
  font-size: 170px;
  line-height: 120%;
  text-shadow: 0 45px 100px rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 3;
}
.article__voile {
  height: 800px;
  position: absolute;
  top: 100vh;
  width: 100%;
  background: var(--blue);
  z-index: 1;
}
.article__voile > div {
  height: 100%;
}
.article__content {
  position: relative;
  margin: -200px auto 0;
  width: 1070px;
  z-index: 40;
}
.article__phrase {
  font-size: 48px;
  line-height: 130%;
  font-family: 'AGFatumC', sans-serif;
  text-transform: uppercase;
  padding: 40px 50px;
  background: var(--bg);
  box-shadow: 0 23px 70px 5px rgba(0, 0, 0, 0.2);
  color: var(--white);
  display: flex;
  align-items: center;
  min-height: 250px;
}
.article__phrase2 {
  font-size: 29px;
  line-height: 52px;
  text-transform: uppercase;
  padding: 100px 0;
  text-align: center;
}
.article__text {
  background: var(--white);
  padding: 30px;
  font-size: 25px;
  line-height: 36px;
  color: var(--blue);
  font-weight: 300;
}
.article__text b {
  text-transform: uppercase;
}
.article__back {
  font-size: 58px;
  line-height: 120%;
  text-transform: uppercase;
  padding: 80px 0;
  text-align: center;
  text-decoration: underline;
  color: var(--white);
  font-family: 'AGFatumC', sans-serif;
}
.article__back:hover {
  text-decoration: none;
}
.article .line {
  width: 25px;
  height: 440px;
  left: 250px;
  top: 70%;
  transform: translateY(500px);
}
.article .line--2 {
  left: auto;
  right: 270px;
  top: 60%;
}
.article .square {
  transform: translateY(400px);
  width: 180px;
  height: 150px;
  left: 290px;
  top: 98%;
}
.article .square--2 {
  width: 150px;
  height: 400px;
  left: auto;
  right: 0;
  top: 35%;
}
p {
  padding: 0;
  margin: 0;
}
p + p {
  margin-top: 25px;
}
.title-magic span {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.title-magic--finish span {
  opacity: 1;
}
/*.w-full{
  z-index: 2; //position: relative;
  >div{
    z-index: 2; //position: relative;
  }
}*/
.slider-outer {
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  position: absolute;
  overflow: hidden;
  height: 100%;
}
.slider-outer .swiper-slide {
  width: auto;
  padding: 0 20px;
}
.slider-outer .swiper-slide:first-child {
  padding-left: 100px;
}
.slider-outer .swiper-slide:last-child {
  padding-right: 100px;
}
.slider-outer .swiper {
  overflow: visible;
  top: 50%;
  margin-top: -325px;
}
.slider-outer .swiper-horizontal > .swiper-scrollbar {
  left: 15%;
  bottom: auto;
  z-index: 50;
  height: 5px;
  width: 70%;
  top: -120px;
  background: transparent;
}
.slider-outer .swiper-scrollbar-drag {
  background: var(--white);
}
.slider-outer .swiper-button-next,
.slider-outer .swiper-button-prev {
  top: 0;
  width: 400px;
  height: 100%;
  margin-top: 0;
  left: 0;
  right: auto;
}
.slider-outer .swiper-button-next:after,
.slider-outer .swiper-button-prev:after {
  display: none;
}
.slider-outer .swiper-button-next {
  right: 0;
  left: auto;
}
.slider-item {
  line-height: 0;
  height: 650px;
}
body {
  font-size: 0.83333333vw;
}
h1,
.h1 {
  font-size: 2.08333333vw;
  margin: 0 0 0.78125vw;
}
h2,
.h2 {
  font-size: 1.5625vw;
  margin: 0 0 0.78125vw;
}
h3,
.h3 {
  font-size: 1.04166667vw;
  margin: 0 0 0.78125vw;
}
.tl {
  top: 1.30208333vw;
  left: 1.30208333vw;
}
.tl.hidden {
  transform: translateY(-5.20833333vw);
}
.br {
  bottom: 1.5625vw;
  right: 1.82291667vw;
  font-size: 0.72916667vw;
}
.br:before {
  height: 2.34375vw;
  bottom: -1.5625vw;
  left: -1.82291667vw;
}
.bl {
  bottom: 3.38541667vw;
  left: 1.30208333vw;
  font-size: 0.390625vw;
  width: 12.5vw;
}
.bl:before {
  width: 0.15625vw;
  height: 1.30208333vw;
  top: 0.52083333vw;
  left: -2.34375vw;
}
.tr {
  padding: 0.9375vw 1.04166667vw 0.9375vw 0;
  width: 7.29166667vw;
  height: 5.72916667vw;
}
.tr:hover .hamburger:not(.close) span {
  margin-left: 0.52083333vw;
}
.tr:hover .hamburger:not(.close) span:first-child {
  margin-left: 2.34375vw;
}
.tr:hover .hamburger:not(.close) span:last-child {
  margin-left: 2.08333333vw;
}
.logo {
  font-size: 1.5625vw;
}
.hamburger span {
  width: 2.60416667vw;
  margin: 0.15625vw 0 0.15625vw 0.83333333vw;
}
.hamburger span:first-child {
  margin-left: 1.5625vw;
}
.hamburger span:last-child {
  margin-left: 2.60416667vw;
}
.hamburger.open span {
  margin-left: 3.38541667vw;
}
.hamburger.open span:first-child {
  margin-left: 1.30208333vw;
}
.hamburger.open span:last-child {
  margin-left: 2.34375vw;
}
.hamburger.close {
  margin-bottom: 1.04166667vw;
}
.hamburger.close span:first-child {
  margin: 0 0 -0.26041667vw 2.08333333vw;
}
.hamburger.close span:last-child {
  margin: -0.26041667vw 0 0 2.08333333vw;
}
.pic {
  width: 61.45833333vw;
  height: 45.83333333vw;
}
.section {
  margin-bottom: 10.41666667vw;
}
.section--1 .pic {
  transform: translateY(-1.5625vw);
}
.section--1 .title {
  font-size: 5.78125vw;
}
.section--1 .title-outer {
  left: 27.60416667vw;
}
.section--1 .title > div:first-child {
  font-size: 4.16666667vw;
  margin-left: 2.08333333vw;
}
.section--1 .btn-fly {
  margin: -3.90625vw 0 0 -8.85416667vw;
}
.section--1 .square {
  left: 51.5625vw;
  top: 100%;
  margin-top: -9.89583333vw;
}
.section--2 .title {
  font-size: 6.66666667vw;
}
.section--2 .title-outer {
  left: 77.08333333vw;
}
.section--2 .title > div {
  transform: translateX(-10.41666667vw);
}
.section--2 .title > div:nth-child(2) {
  font-size: 3.69791667vw;
  margin-left: 8.33333333vw;
}
.section--2 .title.up > div {
  transform: translateX(10.41666667vw);
}
.section--2.active .title-outer {
  transform: translateY(-19.79166667vw);
}
.section--2 .btn-fly {
  margin: 4.6875vw 0 0 8.85416667vw;
}
.section--2 .pic {
  width: 64.58333333vw;
}
.section--2 .square {
  left: 46.35416667vw;
  top: -1.5625vw;
}
.section--2 .line {
  left: 9.89583333vw;
  top: 100%;
  margin-top: -7.8125vw;
}
.section--3 .title {
  font-size: 7.65625vw;
}
.section--3 .title-outer {
  left: 28.64583333vw;
}
.section--3 .title > div:nth-child(2) {
  font-size: 4.0625vw;
  margin-left: 13.02083333vw;
  margin-top: -0.52083333vw;
}
.section--3 .btn-fly {
  margin: 10.9375vw 0 0 -36.97916667vw;
}
.section--3 .square {
  left: 10.41666667vw;
  top: -2.60416667vw;
}
.section--3 .line {
  left: 69.01041667vw;
  top: 100%;
  margin-top: -12.5vw;
}
.section--4 .title {
  font-size: 6.51041667vw;
}
.section--4 .title-outer {
  left: 83.33333333vw;
  transform: translateX(-5.20833333vw);
}
.section--4 .title > div:nth-child(1) {
  margin-left: 17.1875vw;
}
.section--4 .title > div:nth-child(3) {
  font-size: 3.69791667vw;
  margin-left: 3.125vw;
}
.section--4 .btn-fly {
  margin: 16.66666667vw 0 0 4.16666667vw;
}
.section--4 .square {
  left: 68.75vw;
  top: -1.04166667vw;
}
.section--4 .line {
  left: 11.71875vw;
  top: 100%;
  margin-top: -11.45833333vw;
}
.section--5 .pic {
  width: 57.29166667vw;
  height: 41.66666667vw;
}
.section--5 .title {
  font-size: 3.54166667vw;
}
.section--5 .title-outer {
  left: 28.64583333vw;
  transform: translateX(-2.60416667vw);
}
.section--5 .title > div:nth-child(1) {
  margin-left: 2.60416667vw;
}
.section--5 .title > div:nth-child(2) {
  font-size: 8.95833333vw;
  margin-left: 3.64583333vw;
}
.section--5 .btn-fly {
  margin: 19.79166667vw 0 0 -7.29166667vw;
}
.section--5 .square {
  left: 7.8125vw;
  top: 100%;
  margin-top: -9.89583333vw;
}
.section--5 .line {
  left: 61.97916667vw;
  top: 0;
  margin-top: -4.16666667vw;
}
.section--6 .title {
  font-size: 7.8125vw;
}
.section--6 .title-outer {
  left: 75.52083333vw;
  transform: translateY(-0.52083333vw);
}
.section--6 .title > div:nth-child(2) {
  font-size: 4.42708333vw;
  margin-left: 7.8125vw;
  margin-top: -0.52083333vw;
}
.section--6 .btn-fly {
  margin: 14.0625vw 0 0 40.625vw;
}
.section--6 .square {
  left: 55.72916667vw;
  top: -1.04166667vw;
}
.section--6 .line {
  left: 41.92708333vw;
  top: 100%;
  margin-top: -11.19791667vw;
}
.section.prev .pic {
  transform: translateY(-13.02083333vw);
}
.section.prev .square {
  transform: translateY(-26.04166667vw);
}
.section.prev .line {
  transform: translateY(-26.04166667vw);
}
.section.next .pic {
  transform: translateY(13.02083333vw);
}
.section.next .square {
  transform: translateY(26.04166667vw);
}
.section.next .line {
  transform: translateY(26.04166667vw);
}
.square {
  width: 9.89583333vw;
  height: 9.89583333vw;
}
.line {
  width: 1.30208333vw;
  height: 12.5vw;
}
.title {
  font-size: 9.94791667vw;
}
.title > div {
  transform: translateX(-5.20833333vw);
}
.title > div:nth-child(2) {
  transform: translateX(-7.8125vw);
}
.title > div:nth-child(3) {
  transform: translateX(-10.41666667vw);
}
.title > div:nth-child(4) {
  transform: translateX(-13.02083333vw);
}
.title.up > div {
  transform: translateX(5.20833333vw);
}
.title.up > div:nth-child(2),
.title.up > div:nth-child(3),
.title.up > div:nth-child(4) {
  transform: translateX(5.20833333vw);
}
.title.show > div {
  transform: translateX(0) translateY(0);
}
.title.show > div:nth-child(2),
.title.show > div:nth-child(3),
.title.show > div:nth-child(4) {
  transform: translateX(0) translateY(0);
}
.btn {
  font-size: 0.98958333vw;
  padding: 0.52083333vw;
}
.btn-fly {
  width: 8.33333333vw;
  height: 8.33333333vw;
}
.menu-item {
  font-size: 2.86458333vw;
  margin-bottom: 2.86458333vw;
}
.menu-item:before {
  width: 15.10416667vw;
  height: 0.26041667vw;
  left: -23.4375vw;
}
.menu-item:hover {
  padding-left: 12.5vw;
}
.menu-item:hover:before {
  left: -6.25vw;
}
.menu__inner {
  padding: 3.64583333vw 4.16666667vw 0;
}
.menu-float {
  width: 31.25vw;
  height: 19.27083333vw;
}
.menu__bottom {
  font-size: 1.35416667vw;
  padding: 2.08333333vw 4.16666667vw;
}
.menu__link {
  margin-right: 5.20833333vw;
}
.menu__link.active {
  font-size: 2.08333333vw;
}
.article__name {
  font-size: 8.85416667vw;
  text-shadow: 0 2.34375vw 5.20833333vw rgba(0, 0, 0, 0.8);
}
.article__voile {
  height: 41.66666667vw;
}
.article__content {
  margin: -10.41666667vw auto 0;
  width: 55.72916667vw;
}
.article__phrase {
  font-size: 2.5vw;
  padding: 2.08333333vw 2.60416667vw;
  box-shadow: 0 1.19791667vw 3.64583333vw 0.26041667vw rgba(0, 0, 0, 0.2);
  min-height: 13.02083333vw;
}
.article__phrase2 {
  font-size: 1.51041667vw;
  line-height: 2.70833333vw;
  padding: 5.20833333vw 0;
}
.article__text {
  padding: 1.5625vw;
  font-size: 1.30208333vw;
  line-height: 1.875vw;
}
.article__back {
  font-size: 3.02083333vw;
  padding: 4.16666667vw 0;
}
.article .line {
  width: 1.30208333vw;
  height: 22.91666667vw;
  left: 13.02083333vw;
  transform: translateY(26.04166667vw);
}
.article .line--2 {
  left: auto;
  right: 14.0625vw;
}
.article .square {
  transform: translateY(20.83333333vw);
  width: 9.375vw;
  height: 7.8125vw;
  left: 15.10416667vw;
}
.article .square--2 {
  width: 7.8125vw;
  height: 20.83333333vw;
  left: auto;
  right: 0;
}
p {
  padding: 0;
  margin: 0;
}
p + p {
  margin-top: 1.30208333vw;
}
.slider-outer .swiper-slide {
  padding: 0 1.04166667vw;
}
.slider-outer .swiper-slide:first-child {
  padding-left: 5.20833333vw;
}
.slider-outer .swiper-slide:last-child {
  padding-right: 5.20833333vw;
}
.slider-outer .swiper {
  margin-top: -16.92708333vw;
}
.slider-outer .swiper-horizontal > .swiper-scrollbar {
  height: 0.26041667vw;
  top: -6.25vw;
}
.slider-outer .swiper-button-next,
.slider-outer .swiper-button-prev {
  width: 20.83333333vw;
}
.slider-item {
  height: 33.85416667vw;
}
@media only screen and (max-width: 1023px) {
.tl {
    top: 10px;
    left: 10px;
}
.tl.hidden {
    transform: translateY(-100px);
}
.br {
    bottom: 40px;
    right: auto;
    position: absolute;
    left: 20px;
    font-size: 10px;
    line-height: 120%;
}
.br:before {
    display: none;
}
.bl {
    display: none;
}
.tr {
    padding: 18px 20px 18px 0;
    width: 110px;
    height: 60px;
}
.tr:hover .hamburger:not(.close) span {
    margin-left: 50px;
}
.tr:hover .hamburger:not(.close) span:first-child {
    margin-left: 25px;
}
.tr:hover .hamburger:not(.close) span:last-child {
    margin-left: 40px;
}
.logo {
    font-size: 15px;
    line-height: 120%;
}
.hamburger span {
    width: 30px;
    height: 1px;
    margin: 2px 0 2px 35px;
}
.hamburger span:first-child {
    margin-left: 45px;
}
.hamburger span:last-child {
    margin-left: 60px;
}
.hamburger.open span {
    margin-left: 55px;
}
.hamburger.open span:first-child {
    margin-left: 25px;
}
.hamburger.open span:last-child {
    margin-left: 35px;
}
.hamburger.close {
    margin-bottom: 10px;
}
.hamburger.close span:first-child {
    margin: 0 0 -3px 40px;
}
.hamburger.close span:last-child {
    margin: -3px 0 0 40px;
}
p {
    padding: 0;
    margin: 0;
}
p + p {
    margin-top: 20px;
}
.menu-item {
    font-size: 16px;
    margin-bottom: 50px;
}
.menu-item:before {
    width: 45px;
    height: 3px;
    left: -100px;
}
.menu-item:hover {
    padding-left: 50px;
}
.menu-item:hover:before {
    left: -15px;
}
.menu-float {
    width: 375px;
    height: 220px;
}
.menu-float-item.active {
    opacity: 0.5;
}
.menu__link {
    margin-right: 30px;
}
.menu__link.active {
    font-size: 20px;
}
.menu__inner {
    padding: 120px 15px 0;
}
.menu__bottom {
    font-size: 10px;
    padding: 20px;
}
.menu__bottom a {
    display: inline-block;
    transition: all 0.3s ease;
}
.menu__bottom .hidden {
    transform: translateX(150px);
}
.btn {
    font-size: 12px;
    line-height: 130%;
    padding: 10px;
}
.btn-fly {
    width: 105px;
    height: 105px;
}
.title {
    line-height: 120%;
    font-size: 56px;
}
.title > div {
    transform: translateX(-100px);
    transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
}
.title > div:nth-child(2) {
    transform: translateX(-150px);
}
.title > div:nth-child(3) {
    transform: translateX(-200px);
}
.title > div:nth-child(4) {
    transform: translateX(-250px);
}
.title.up > div {
    transform: translateX(100px);
}
.title.up > div:nth-child(2),
  .title.up > div:nth-child(3),
  .title.up > div:nth-child(4) {
    transform: translateX(100px);
}
.title.show > div {
    transform: translateX(0) translateY(0);
}
.title.show > div:nth-child(2),
  .title.show > div:nth-child(3),
  .title.show > div:nth-child(4) {
    transform: translateX(0) translateY(0);
}
.pic {
    width: 600px;
    height: 448px;
    opacity: 0.9;
    transform: translateY(150px);
}
.square {
    width: 90px;
    height: 200px;
    transform: translateY(300px);
}
.line {
    width: 15px;
    height: 180px;
    transform: translateY(300px);
}
.section {
    margin-bottom: 75px;
}
.section--1 .title {
    font-size: 56px;
}
.section--1 .title-outer {
    left: 140px;
    transform: translateY(-100px);
}
.section--1 .title > div:nth-child(1) {
    font-size: 45px;
    margin-left: 100px;
}
.section--1 .title > div:nth-child(2) {
    font-size: 75px;
}
.section--1 .btn-fly {
    margin: 175px 0 0 90px;
}
.section--1 .square {
    left: auto;
    right: 0;
    margin-top: -70px;
    width: 110px;
    height: 100px;
}
.section--2 .title-outer {
    left: 35%;
    transform: none!important;
}
.section--2 .title > div {
    transform: none;
}
.section--2 .title > div:nth-child(1) {
    font-size: 50px;
}
.section--2 .title > div:nth-child(2) {
    font-size: 75px;
    margin-left: 100px;
    margin-top: 50px;
}
.section--2 .title .pic {
    width: 431.25px;
    height: 322px;
}
.section--2 .btn-fly {
    margin: 125px 0 0 100px;
}
.section--2 .square {
    display: none;
}
.section--2 .line {
    left: 0;
    top: 320px;
    margin-top: 0;
}
.section--3 .title-outer {
    left: 10px;
    transform: none;
}
.section--3 .title > div {
    font-size: 84px;
}
.section--3 .title > div:nth-child(2) {
    font-size: 40px;
    margin-left: 130px;
    margin-top: 50px;
}
.section--3 .btn-fly {
    margin: 250px 0 0 100px;
}
.section--3 .pic {
    width: 562.5px;
    height: 420px;
}
.section--3 .square {
    left: -10px;
    top: 0;
}
.section--3 .line {
    left: 340px;
    margin-top: -30px;
}
.section--4 .title {
    font-size: 89px;
}
.section--4 .title-outer {
    left: 150px;
}
.section--4 .title > div:nth-child(1) {
    font-size: 50px;
    margin-left: 200px;
}
.section--4 .title > div:nth-child(2) {
    margin-top: -30px;
    font-size: 80px;
    margin-left: 50px;
}
.section--4 .title > div:nth-child(3) {
    font-size: 40px;
    margin-left: 150px;
    margin-top: -20px;
}
.section--4 .btn-fly {
    margin: 180px 0 0 105px;
}
.section--4 .square {
    left: -20px;
    top: 100%;
    margin-top: -120px;
}
.section--4 .line {
    left: 230px;
    margin-top: -60px;
}
.section--5 .pic {
    width: 487.5px;
    height: 364px;
}
.section--5 .title-outer {
    left: 130px;
}
.section--5 .title > div {
    transform: none!important;
}
.section--5 .title > div:nth-child(1) {
    font-size: 40px;
}
.section--5 .title > div:nth-child(2) {
    font-size: 90px;
    margin-left: 50px;
    margin-top: 20px;
}
.section--5 .btn-fly {
    margin: 215px 0 0 80px;
}
.section--5 .square {
    display: none;
}
.section--5 .line {
    display: none;
}
.section--6 {
    margin-bottom: 0!important;
}
.section--6 .title-outer {
    left: 150px;
    transform: none;
}
.section--6 .title > div:nth-child(1) {
    font-size: 80px;
}
.section--6 .title > div:nth-child(2) {
    font-size: 40px;
    margin-left: 35px;
    margin-top: 40px;
}
.section--6 .btn-fly {
    margin: 215px 0 0 80px;
}
.section--6 .square {
    left: 300px;
    top: 380px;
}
.section--6 .line {
    left: 40px;
    margin-top: -120px;
}
.section.prev .square {
    transform: translateY(-300px);
}
.section.prev .line {
    transform: translateY(-200px);
}
.section.next .square {
    transform: translateY(200px);
}
.section.next .line {
    transform: translateY(300px);
}
.article {
    padding-top: 50px;
}
.article__bg {
    height: 270px;
    width: 100%;
    z-index: 2;
}
.article__name {
    font-size: 54px;
    line-height: 65px;
    top: 200px;
    width: 90%;
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.8);
}
.article__voile {
    height: 900px;
    top: 0;
}
.article__content {
    margin: -20px auto 0;
    width: 305px;
}
.article__phrase {
    font-size: 14px;
    line-height: 150%;
    padding: 15px 10px;
    box-shadow: 0 23px 40px 5px rgba(0, 0, 0, 0.2);
    min-height: 0;
}
.article__phrase2 {
    font-size: 12px;
    line-height: 21px;
    padding: 40px 5%;
}
.article__text {
    padding: 15px;
    font-size: 13px;
    line-height: 17px;
}
.article__back {
    font-size: 38px;
    line-height: 120%;
    padding: 50px 0 100px;
}
.article .line {
    width: 15px;
    height: 200px;
    left: 0;
    top: 40%;
    transform: translateY(300px);
}
.article .line--2 {
    left: auto;
    right: 20px;
    top: 50%;
}
.article .square {
    transform: translateY(200px);
    width: 90px;
    height: 200px;
    left: 0;
    top: 80%;
}
.article .square--2 {
    width: 45px;
    height: 200px;
    left: auto;
    right: 0;
    top: 30%;
}
ul {
    padding-left: 20px;
}
.slider-outer {
    top: 0;
    padding: 65px 0;
    text-align: center;
    margin-top: 0;
    overflow: visible;
    height: auto;
}
.slider-outer:before,
  .slider-outer:after {
    position: fixed;
    content: "";
    height: 65px;
    background: var(--black);
    width: 100%;
    top: 0;
    left: 0;
}
.slider-outer:after {
    top: auto;
    bottom: 0;
}
.slider-item {
    line-height: 0;
    margin: 0 auto 20px;
    height: auto;
    width: 310px;
}
.tl {
    top: 2.66666667vw;
    left: 2.66666667vw;
}
.tl.hidden {
    transform: translateY(-26.66666667vw);
}
.br {
    bottom: 10.66666667vw;
    right: auto;
    left: 5.33333333vw;
    font-size: 2.66666667vw;
}
.tr {
    padding: 4.8vw 5.33333333vw 4.8vw 0;
    width: 29.33333333vw;
    height: 16vw;
}
.tr:hover .hamburger:not(.close) span {
    margin-left: 13.33333333vw;
}
.tr:hover .hamburger:not(.close) span:first-child {
    margin-left: 6.66666667vw;
}
.tr:hover .hamburger:not(.close) span:last-child {
    margin-left: 10.66666667vw;
}
.logo {
    font-size: 4vw;
    line-height: 120%;
}
.hamburger span {
    width: 8vw;
    height: 0.26666667vw;
    margin: 0.53333333vw 0 0.53333333vw 9.33333333vw;
}
.hamburger span:first-child {
    margin-left: 12vw;
}
.hamburger span:last-child {
    margin-left: 16vw;
}
.hamburger.open span {
    margin-left: 14.66666667vw;
}
.hamburger.open span:first-child {
    margin-left: 6.66666667vw;
}
.hamburger.open span:last-child {
    margin-left: 9.33333333vw;
}
.hamburger.close {
    margin-bottom: 2.66666667vw;
}
.hamburger.close span:first-child {
    margin: 0 0 -0.8vw 10.66666667vw;
}
.hamburger.close span:last-child {
    margin: -0.8vw 0 0 10.66666667vw;
}
p {
    padding: 0;
    margin: 0;
}
p + p {
    margin-top: 4vw;
}
.menu-item {
    font-size: 4.26666667vw;
    margin-bottom: 13.33333333vw;
}
.menu-item:before {
    width: 12vw;
    height: 0.8vw;
    left: -26.66666667vw;
}
.menu-item:hover {
    padding-left: 13.33333333vw;
}
.menu-item:hover:before {
    left: -4vw;
}
.menu-float {
    width: 100vw;
    height: 58.66666667vw;
}
.menu__link {
    margin-right: 8vw;
}
.menu__link.active {
    font-size: 5.33333333vw;
}
.menu__inner {
    padding: 32vw 4vw 0;
}
.menu__bottom {
    font-size: 2.66666667vw;
    padding: 5.33333333vw;
}
.menu__bottom .hidden {
    transform: translateX(40vw);
}
.btn {
    font-size: 3.2vw;
    line-height: 130%;
    padding: 2.66666667vw;
}
.btn-fly {
    width: 28vw;
    height: 28vw;
}
.title {
    line-height: 120%;
    font-size: 14.93333333vw;
}
.title > div {
    transform: translateX(-26.66666667vw);
}
.title > div:nth-child(2) {
    transform: translateX(-40vw);
}
.title > div:nth-child(3) {
    transform: translateX(-53.33333333vw);
}
.title > div:nth-child(4) {
    transform: translateX(-66.66666667vw);
}
.title.up > div {
    transform: translateX(26.66666667vw);
}
.title.up > div:nth-child(2),
  .title.up > div:nth-child(3),
  .title.up > div:nth-child(4) {
    transform: translateX(26.66666667vw);
}
.title.show > div {
    transform: translateX(0) translateY(0);
}
.title.show > div:nth-child(2),
  .title.show > div:nth-child(3),
  .title.show > div:nth-child(4) {
    transform: translateX(0) translateY(0);
}
.pic {
    width: 160vw;
    height: 119.46666667vw;
    transform: translateY(40vw);
}
.square {
    width: 24vw;
    height: 53.33333333vw;
    transform: translateY(80vw);
}
.line {
    width: 4vw;
    height: 48vw;
    transform: translateY(80vw);
}
.section {
    margin-bottom: 20vw;
}
.section--1 .title {
    font-size: 14.93333333vw;
}
.section--1 .title-outer {
    left: 37.33333333vw;
    transform: translateY(-26.66666667vw);
}
.section--1 .title > div:nth-child(1) {
    font-size: 12vw;
    margin-left: 26.66666667vw;
}
.section--1 .title > div:nth-child(2) {
    font-size: 20vw;
}
.section--1 .btn-fly {
    margin: 46.66666667vw 0 0 24vw;
}
.section--1 .square {
    left: auto;
    right: 0;
    margin-top: -18.66666667vw;
    width: 29.33333333vw;
    height: 26.66666667vw;
}
.section--2 .title > div:nth-child(1) {
    font-size: 13.33333333vw;
}
.section--2 .title > div:nth-child(2) {
    font-size: 20vw;
    margin-left: 26.66666667vw;
    margin-top: 13.33333333vw;
}
.section--2 .btn-fly {
    margin: 33.33333333vw 0 0 26.66666667vw;
}
.section--2 .line {
    left: 0;
    top: 85.33333333vw;
    margin-top: 0;
}
.section--2 .pic {
    width: 115vw;
    height: 85.86666667vw;
}
.section--3 .title-outer {
    left: 42.66666667vw;
}
.section--3 .title > div {
    font-size: 22.4vw;
}
.section--3 .title > div:nth-child(2) {
    font-size: 10.66666667vw;
    margin-left: 34.66666667vw;
    margin-top: 13.33333333vw;
}
.section--3 .btn-fly {
    margin: 66.66666667vw 0 0 26.66666667vw;
}
.section--3 .pic {
    width: 150vw;
    height: 112vw;
}
.section--3 .square {
    left: -2.66666667vw;
    top: 0;
}
.section--3 .line {
    left: 90.66666667vw;
    margin-top: -8vw;
}
.section--4 .title {
    font-size: 23.73333333vw;
}
.section--4 .title-outer {
    left: 40vw;
}
.section--4 .title > div:nth-child(1) {
    font-size: 13.33333333vw;
    margin-left: 53.33333333vw;
}
.section--4 .title > div:nth-child(2) {
    margin-top: -8vw;
    font-size: 21.33333333vw;
    margin-left: 13.33333333vw;
}
.section--4 .title > div:nth-child(3) {
    font-size: 10.66666667vw;
    margin-left: 40vw;
    margin-top: -5.33333333vw;
}
.section--4 .btn-fly {
    margin: 48vw 0 0 28vw;
}
.section--4 .square {
    margin-top: -32vw;
    left: -5.33333333vw;
}
.section--4 .line {
    left: 61.33333333vw;
    margin-top: -16vw;
}
.section--5 .pic {
    width: 130vw;
    height: 97.06666667vw;
}
.section--5 .title-outer {
    left: 34.66666667vw;
}
.section--5 .title > div:nth-child(1) {
    font-size: 10.66666667vw;
}
.section--5 .title > div:nth-child(2) {
    font-size: 24vw;
    margin-left: 13.33333333vw;
    margin-top: 5.33333333vw;
}
.section--5 .btn-fly {
    margin: 57.33333333vw 0 0 21.33333333vw;
}
.section--6 .title-outer {
    left: 40vw;
}
.section--6 .title > div:nth-child(1) {
    font-size: 21.33333333vw;
}
.section--6 .title > div:nth-child(2) {
    font-size: 10.66666667vw;
    margin-left: 9.33333333vw;
    margin-top: 10.66666667vw;
}
.section--6 .btn-fly {
    margin: 57.33333333vw 0 0 21.33333333vw;
}
.section--6 .square {
    left: 80vw;
    top: 101.33333333vw;
}
.section--6 .line {
    left: 10.66666667vw;
    margin-top: -32vw;
}
.section.prev .square {
    transform: translateY(-80vw);
}
.section.prev .line {
    transform: translateY(-53.33333333vw);
}
.section.next .square {
    transform: translateY(53.33333333vw);
}
.section.next .line {
    transform: translateY(80vw);
}
.article {
    padding-top: 13.33333333vw;
}
.article__bg {
    height: 72vw;
}
.article__name {
    font-size: 14.4vw;
    line-height: 17.33333333vw;
    top: 53.33333333vw;
    text-shadow: 0 4vw 8vw rgba(0, 0, 0, 0.8);
}
.article__voile {
    height: 240vw;
    top: 0;
}
.article__content {
    margin: -5.33333333vw auto 0;
    width: 81.33333333vw;
}
.article__phrase {
    font-size: 3.73333333vw;
    line-height: 150%;
    padding: 4vw 2.66666667vw;
    box-shadow: 0 6.13333333vw 10.66666667vw 1.33333333vw rgba(0, 0, 0, 0.2);
}
.article__phrase2 {
    font-size: 3.2vw;
    line-height: 5.6vw;
    padding: 10.66666667vw 5%;
}
.article__text {
    padding: 4vw;
    font-size: 3.46666667vw;
    line-height: 4.53333333vw;
}
.article__back {
    font-size: 10.13333333vw;
    line-height: 120%;
    padding: 13.33333333vw 0 26.66666667vw;
}
.article .line {
    width: 4vw;
    height: 53.33333333vw;
    left: 0;
    top: 40%;
    transform: translateY(80vw);
}
.article .line--2 {
    left: auto;
    right: 5.33333333vw;
    top: 50%;
}
.article .square {
    transform: translateY(53.33333333vw);
    width: 24vw;
    height: 53.33333333vw;
    left: 0;
    top: 80%;
}
.article .square--2 {
    width: 12vw;
    height: 53.33333333vw;
    left: auto;
    right: 0;
    top: 30%;
}
ul {
    padding-left: 5.33333333vw;
}
.slider-outer {
    padding: 17.33333333vw 0;
}
.slider-outer:before,
  .slider-outer:after {
    height: 17.33333333vw;
}
.slider-item {
    margin: 0 auto 5.33333333vw;
    width: 82.66666667vw;
}
}
.cursor {
  position: fixed;
  line-height: 0;
  z-index: 9997;
  pointer-events: none;
  border: 1px solid var(--white);
  border-radius: 50%;
  margin: -1.82291667vw 0 0 -1.82291667vw;
  display: inline-block;
  width: 3.64583333vw;
  height: 3.64583333vw;
  background: transparent;
  transition: background 0.3s ease, transform 0.5s ease, width 1s ease, height 1s ease, margin 1s ease;
}
.cursor:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/images/plus.svg) no-repeat center / 2.08333333vw auto;
  opacity: 0;
  transition: all 0.3s ease;
}
.cursor:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/images/arrow.svg) no-repeat center / 4.16666667vw auto;
  opacity: 0;
  transition: all 0.3s ease;
}
.cursor--fast {
  transition: none;
}
.cursor--hover {
  background: var(--white);
  mix-blend-mode: difference;
  transform: scale(0.8);
}
.cursor.hover-black {
  border-color: var(--black);
}
.cursor--big {
  width: 14.0625vw;
  height: 14.0625vw;
  margin: -7.03125vw 0 0 -7.03125vw;
}
.cursor--filled {
  background: var(--white);
  width: 8.33333333vw;
  height: 8.33333333vw;
  margin: -4.16666667vw 0 0 -4.16666667vw;
}
.cursor--filled:after {
  opacity: 1;
}
.cursor--crazy {
  background: var(--white);
  width: 250vw;
  height: 250vw;
  margin: -125vw 0 0 -125vw;
}
.cursor--crazy:after {
  opacity: 1;
}
.cursor--next,
.cursor--prev {
  mix-blend-mode: normal;
}
.cursor--next:before,
.cursor--prev:before {
  opacity: 1;
}
.cursor--prev {
  mix-blend-mode: normal;
}
.cursor--prev:before {
  transform: scaleX(-1);
}
.cursor-dot {
  position: fixed;
  line-height: 0;
  z-index: 9996;
  pointer-events: none;
  width: 0.52083333vw;
  height: 0.52083333vw;
  border-radius: 50%;
  background: var(--white);
  margin: -0.26041667vw 0 0 -0.26041667vw;
  transition: width 2s ease, height 2s ease, margin 2s ease;
}
.cursor-dot.cursor--hover {
  background: var(--white);
}
.cursor-dot.hover-black {
  background: var(--black);
}
.cursor-dot.cursor--tiny {
  width: 0;
  height: 0;
  margin: 0;
}
.cursor-dot.cursor--crazy {
  width: 250vw;
  height: 250vw;
  margin: -125vw 0 0 -125vw;
}
.cursor-dot.cursor--top {
  z-index: 9998;
}
.slide {
  position: fixed;
  z-index: -9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: transform 1s ease;
}
.slide--step1 {
  transform: translateY(0);
  z-index: 9999;
}
.slide--step2 {
  transform: translateY(-100%);
  z-index: 9999;
}
.hover {
  cursor: pointer;
}
body * {
  cursor: none !important;
}
@media only screen and (max-width: 1023px) {
  /*body {
    * {
      cursor: default !important;
    }
  }

  .cursor {
    display: none!important;

    &-dot {
      display: none!important;
    }
  }*/
.cursor {
    margin: 0;
    width: 0;
    height: 0;
    border: 0;
}
.cursor:after {
    background-size: 10.66666667vw auto;
}
.cursor--big {
    width: 0;
    height: 0;
    margin: 0;
}
.cursor--filled {
    width: 0;
    height: 0;
    margin: 0;
}
.cursor--crazy {
    width: 400vw;
    height: 400vw;
    margin: -200vw 0 0 -200vw;
}
.cursor-dot {
    width: 0;
    height: 0;
    margin: 0;
    border: 0;
}
.cursor-dot.cursor--crazy {
    width: 400vw;
    height: 400vw;
    margin: -200vw 0 0 -200vw;
}
}
